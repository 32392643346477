import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from "gatsby-image"
import Seo from '../components/seo'
import NavMobile from '../components/nav_mobile'
import BackgroundImage from 'gatsby-background-image'
import Copyright from '../components/copyright'

export default ({ data }) => {
    const entry = data.markdownRemark
    return (
        <div className="page">
            <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
            <NavMobile />
            <div className="layout">
                <BackgroundImage fluid={entry.frontmatter.image.childImageSharp.fluid} backgroundColor={`#ffffff`}>
                    <div className="title__gradient">
                        <div className="layout__container">
                            <div className="layout__wide title center">
                                <h1>{entry.frontmatter.heading}</h1>
                            </div>
                        </div>
                    </div>
                </BackgroundImage>
            </div>
            <div className="layout grey">
                <div className="layout__container">
                    <div className="layout__wide">
                        <nav className="breadcrumb">
                            <p><Link to='/'>Home</Link> &nbsp;&rsaquo;&nbsp; <strong>{entry.frontmatter.title}</strong></p>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="layout body">
                <div className="layout__container">
                    <div className="layout__wide">
                        <div className="grid grid__2-1 grid__gap--48 text">
                            <div>
                                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
                                <a href="https://bookings.gettimely.com/andrewwaldegraveltd/book?uri=https%3A%2F%2Fbook.gettimely.com%2FBooking%2FLocation%2F153135%3Fmobile%3DTrue%26params%3D%25253fclient-login%25253dtrue" rel="nofollow noopener" title="Contact" className="btn btn__large btn__margin">Book Online &nbsp;►</a>
                            </div>
                            <div className="aside">
                                <Img fluid={entry.frontmatter.side_image.childImageSharp.fluid} alt={entry.frontmatter.side_heading} />
                                <h3>{entry.frontmatter.side_heading}</h3>
                                <ul>
                                    {entry.frontmatter.side_text.map( (entry, i) => (
                                        <li key={i}>{entry.text}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Copyright />
        </div>
    )
}

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
                title
				page_title
                meta_description
                heading
                image {
                    childImageSharp {
                        fluid(maxWidth: 1280) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
                side_heading
                side_text {
                    text
                }
                side_image {
                    childImageSharp {
                        fluid(maxWidth: 640, maxHeight: 480) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
			}
			fields {
				slug
			}
			html
		}
	}
`